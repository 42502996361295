import PropTypes from 'prop-types';
import React from 'react';
import '../styles/global.scss';

const Particular = ({ data }) => {
  return (
    <React.Fragment>
      <div>
        <div className='columns' style={{ maxWidth: '100%' }}>
          <div className='column is-full'>
            <p
              className='SectionHeading'
              style={{
                textAlign: 'center',
              }}
            >
              Looking For Something Particular?
            </p>
          </div>
        </div>
      </div>
      <div className='section particular'>
        <div className='columns'>
          {data.map((i, index) => (
            <div className='column card-particular'>
              <img
                src={i.file.url}
                alt={i.title}
                style={{ width: '25vh', height: 'auto' }}
              />
              <h1 className='lookingTitle'>{i.title}</h1>
              <p className='looking-desc'>{i.description}</p>
              {!index % 2 ? (
                <a
                  className='email'
                  style={{ color: '#009eff' }}
                  href='mailto:info@gonuclei.com'
                >
                  info@gonuclei.com
                </a>
              ) : (
                <a
                  className='email'
                  style={{ color: '#009eff' }}
                  href='mailto:people@gonuclei.com'
                >
                  people@gonuclei.com
                </a>
              )}
            </div>
          ))}
        </div>
      </div>
    </React.Fragment>
  );
};

Particular.propTypes = {
  data: PropTypes.array,
};

export default Particular;
