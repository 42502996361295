import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet';
import Footer from './Footer';
import Navbar from './Navbar';

const Layout = ({ children, isCareer, isWithoutHero }) => {
  return (
    <>
      <Helmet>
        <link
          rel='stylesheet'
          href='https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css'
        />
      </Helmet>
      <Navbar isCareer={isCareer} isWithoutHero={isWithoutHero} />
      {children}
      <Footer />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.any,
  isCareer: PropTypes.bool,
};
Layout.defaultProps = { isCareer: false };

export default Layout;
