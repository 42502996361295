import React from 'react';

const Map = () => {
  return (
    <section className='section'>
      {' '}
      <p
        className='SectionHeading'
        style={{
          textAlign: 'center',
        }}
      >
        How To Find Nuclei?
      </p>
      <div className='columns'>
        <div className='column is-full'>
          <iframe
            width='100%'
            height='800px'
            src='https://maps.google.com/maps?q=Nuclei&t=&z=17&ie=UTF8&iwloc=&output=embed'
            frameborder='10px'
            scrolling='no'
            marginheight='0'
            marginwidth='0'
            title='Nuclei Location'
          ></iframe>
        </div>
      </div>
    </section>
  );
};

export default Map;
