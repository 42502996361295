import React from 'react';
import { graphql } from 'gatsby';
import BottomBar from '../components/BottomBar';
import SEO from '../components/seo';
import Hero from '../components/Hero';
import ContactUsForm from '../components/ContactUsForm';
import Particular from '../components/Particular';
import Map from '../components/Map';
import { Helmet } from 'react-helmet';
import 'normalize.css';
import Layout from '../components/Layout';

const ContactUs = ({ data }) => {
  const bannerImg = data.ogImg.ogimage[2].file.url;
  return (
    <div>
      <SEO
        title='Contact Us'
        description={data.contentfulContactUs.subtitle}
        bannerImage={bannerImg}
        pathname='ContactUs'
      />
      <Helmet>
        <link
          rel='stylesheet'
          href='https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.0.0/animate.min.css'
        />
      </Helmet>
      <Layout>
        <Hero
          title={data.contentfulContactUs.title}
          subtitle={data.contentfulContactUs.subtitle}
          bgImage={data.contentfulContactUs.hero.file.url}
          isProducts={true}
          isHomePage={true}
          isLetsTalk={true}
          isFull='is-fullheight-with-navbar'
        />
        <ContactUsForm />
        <Particular data={data.contentfulContactUs.lookingForParticular} />
        <Map />
        <BottomBar />
      </Layout>
    </div>
  );
};
export const query = graphql`
  {
    ogImg: contentfulOpenGraph {
      ogimage {
        file {
          url
          fileName
        }
      }
    }
    contentfulContactUs {
      hero {
        file {
          url
        }
      }
      subtitle
      title

      lookingForParticular {
        description
        title
        file {
          url
        }
      }
    }
  }
`;
export default ContactUs;
