// @ts-nocheck
import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';
import SchemaOrg from './SchemaOrg';
function SEO({
  description,
  lang,
  image,
  meta,
  title,
  article,
  bannerImage,
  author,
  pathname,
  datePublished,
}) {
  const { site, og } = useStaticQuery(
    graphql`
      query {
        og: contentfulAsset(file: { fileName: { eq: "IndexBanner.jpg" } }) {
          id
          file {
            url
          }
        }
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
            image
            twitterUsername
          }
        }
      }
    `
  );
  const img = og.file.url;
  const {
    defaultTitle,
    defaultDescription,
    siteUrl,
    twitterUsername,
  } = site.siteMetadata;

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    image: `${siteUrl}${image}`,
    url: `${siteUrl}${pathname}`,
  };
  const metaDescription = description || site.siteMetadata.description;

  return (
    <>
      <Helmet
        htmlAttributes={{
          lang,
        }}
        title={title}
        titleTemplate={`%s | ${site.siteMetadata.title}`}
      >
        {(article ? true : null) && (
          <meta property='og:type' content='article' />
        )}
        {/*Schema.org  Google */}
        <meta
          itemprop='name'
          content={`${title} | ${site.siteMetadata.title}`}
        />
        <meta itemprop='description' content={description} />
        <meta itemprop='image' content={bannerImage} />
        <meta name='description' content={description} />
        {/* FB Meta Tags */}
        <meta property='og:url' content={`${siteUrl}/${pathname}`} />
        <meta property='og:site_name' content='Nuclei' />
        <meta property='og:type' content='website' />
        <meta
          property='og:title'
          content={`${title} | ${site.siteMetadata.title}`}
        />
        <meta property='og:description' content={description} />
        <meta property='og:image' content={bannerImage} />
        <meta property='og:image:url' content={bannerImage} />
        <meta property='og:image:secure' content={bannerImage} />

        {/* Twitter Meta Tags */}
        <meta property='twitter:url' content={`${siteUrl}/${pathname}`} />
        <meta name='twitter:card' content='summary_large_image' />
        <meta
          name='twitter:title'
          content={`${title} | ${site.siteMetadata.title}`}
        />
        <meta name='twitter:site' content='@gonuclei' />
        <meta name='twitter:description' content={description} />
        <meta name='twitter:image' content={bannerImage} />
        {/* Linkedin */}
        <meta name='image' property='og:image' content={bannerImage} />
        <meta name='author' content={author} />
      </Helmet>
      <SchemaOrg
        title={title}
        url={`${siteUrl}/${pathname}`}
        defaultTitle='Nuclei'
        article={article}
        image={image}
        description={description}
        canonicalUrl={siteUrl}
        datePublished={datePublished}
      />{' '}
    </>
  );
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
  author: 'Nuclei',
  article: false,
  datePublished: null,
};

SEO.propTypes = {
  description: PropTypes.string.isRequired,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  bannerImage: PropTypes.string.isRequired,
  author: PropTypes.string,
  pathname: PropTypes.string.isRequired,
  datePublished: PropTypes.any,
};

export default SEO;
