import React from 'react';
import Form from './Form';
import '../styles/global.scss';

const ContactUsForm = () => {
  return (
    <section className='section' style={{ backgroundColor: '#f9f9f9' }}>
      <div className='columns'>
        <div className='column is-full'>
          <h1
            className='SectionHeading'
            style={{
              textAlign: 'center',
              paddingTop: '0rem',
              paddingBottom: 0,
              marginBottom: 0,
              fontWeight: 'bold',
            }}
          >
            Connect with your Customers
          </h1>
          <p
            className='SectionSubHeading'
            style={{
              textAlign: 'center',
            }}
          >
            Customer’s expectations with their Banks have grown significantly
          </p>
        </div>
      </div>

      <div className='contact-us-form-width'>
        <Form />
      </div>
    </section>
  );
};

export default ContactUsForm;
