import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import divider from '../images/divider-colored.svg';
import '../styles/global.scss';
import LetsTalk from './LetsTalk';

const BottomBar = () => {
  const data = useStaticQuery(
    graphql`
      query {
        contentfulFooter {
          bethebank
        }
      }
    `
  );
  const [isModal, setModal] = React.useState({ active: false, content: '' });

  return (
    <section
      className='section'
      style={{
        marginTop: '30px',
        backgroundImage: 'linear-gradient(to bottom, #ffffff 22%, #dff3ff)',
        zIndex: '1',
        fontFamily: 'Open Sans',
      }}
    >
      <div className='columns is-mobile'>
        <div
          className='column is-size-2 is-size-4-mobile'
          style={{
            fontWeight: 'bold',
            textAlign: 'center',
            verticalAlign: 'center',
          }}
        >
          <img src={divider} alt='divider' />

          <p className='bottom-bar-text'> {data.contentfulFooter.bethebank}</p>
          <center>
            <p className='control'>
              <div
                className='button'
                onClick={() => setModal({ content: 'is-active', active: true })}
              >
                <span className='button-text'>Interested?</span>
              </div>
            </p>
          </center>
        </div>
      </div>
      {isModal.active && <LetsTalk active={isModal} setModal={setModal} />}
    </section>
  );
};

export default BottomBar;
